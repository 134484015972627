$color-black: #282828;
$color-charcoal: #3b4447;
$color-teal: #5ac4cc;
$color-salmon: #ef476f;
$color-cream: #FFFAEA;
$max-width: 78ch;

body {
    margin: 0;
    padding: 0;
    font-family: Source Sans Pro; }

main {
    display: flex;
    flex-direction: column;
    min-height: 100vh; }

h1, h2, h3, h4, h5, h6 {
    font-family: Source Serif Pro;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1rem; }

h1 {
    font-size: 2.5rem;
    line-height: 3rem; }
h2 {
    font-size: 2rem; }
h3 {
    font-size: 1.7rem; }
h4 {
    font-size: 1.4rem; }
h5 {
    font-size: 1.2rem; }
h6 {
    font-size: 1rem; }
h1, h2, h3, h4, h5, h6 {
    & > a {
        margin-left: 0.5rem; } }
.title {
    font-family: Source Serif Pro;
    font-size: 1rem;
    font-weight: 300;
    width: 100%; }


nav, footer {
    padding: 0 1.5rem;
    display: flex;
    flex-wrap: wrap;
    line-height: 2.5rem;

    & > h1 {
        font-size: 2.5rem; }
    & > a {
        margin-left: 1rem;
        white-space: nowrap; } }

nav, footer {
    & > a:first-child {
        margin-left: 0; } }


img {
    max-width: 100%; }

#content {
    margin-top: 2rem;
    margin-bottom: auto; }

.main-col {
    max-width: $max-width;
    margin-left: 0;
    margin-right: 0;
    padding: 0 1.5rem; }

.date {
    font-weight: 300;
    font-size: 0.85rem; }

.card {
    padding: 2.2rem 2.2rem 1rem 2.2rem;
    border-radius: 8px;
    text-decoration: none;
    transition: all 0.2s ease; }

.card-list {
    display: grid;
    gap: 1.5rem; }

.article-header {
    margin-bottom: 2rem; }

.article-content {
    & > h1, > h2, > h3, > h4, > h5, > h6 {
        border-bottom: 1px solid $color-charcoal; } }

@media (prefers-color-scheme: dark) {
    @import "gist.sass";
    body {
        background: $color-black;
        color: $color-cream; }

    a {
        color: $color-teal; }

    .card {
        background: $color-black;
        color: $color-cream;
        border: solid 1px $color-cream;
        &:hover {
            background: scale-color($color-black, $lightness: -15%); } }

    nav {
        border-bottom: solid 1px $color-cream; }
    footer {
        border-top: solid 1px $color-cream;
        margin-top: 4rem; }

    .article-content {
        & > h1, > h2, > h3, > h4, > h5, > h6 {
            border-bottom: 1px solid $color-cream; } } }


@media (prefers-color-scheme: light) {
    body {
        background: $color-cream;
        color: $color-charcoal; }

    a {
        color: $color-salmon; }

    .card {
        background: $color-cream;
        color: $color-charcoal;
        border: solid 1px $color-charcoal;
        &:hover {
            background: scale-color($color-cream, $lightness: 30%); } }

    nav {
        border-bottom: solid 1px $color-charcoal; }
    footer {
        border-top: solid 1px $color-charcoal; } }

@media (min-width: 50rem) {
    .card-list {
        grid-template-columns: 1fr 1fr; }
    .card:first-child {
        grid-column: span 2; } }

@media (min-width: $max-width) {
    .main-col {
        padding: 0;
        width: 100%;
        margin-left: auto;
        margin-right: auto; }

    nav, footer {
        flex-wrap: nowrap; } }
